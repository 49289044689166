<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 부서 -->
          <c-dept
            :plantCd="searchParam.plantCd"
            type="search"
            :label="patrolInfo.patrolTypeCd == 'PTC0000001' ? '점검 주관부서' : '점검 대상부서'"
            name="vendorCd"
            :isFirstValue="false"
            v-model="searchParam.vendorCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 순회기간 -->
          <c-datepicker
            :range="true"
            label="점검일"
            defaultStart="-3M"
            defaultEnd="0M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :editable="false"
            codeGroupCd="PATROL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="patrolTypeCd"
            label="점검구분"
            v-model="searchParam.patrolTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      :title="patrolInfo.title"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
      checkDisableColumn="improvementFlag"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSAVE" icon="save" @btnClicked="saveItem" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='link' && Boolean(props.row.sopImprovementId)">
          <q-btn push size="xs" icon="search" color="blue-6" round dense @click="linkImprDetail(props.row)"></q-btn>
        </template>
        <template v-if="col.name==='request' && !Boolean(props.row.sopImprovementId)">
          <q-btn push size="xs" icon="construction" color="red-6" round dense @click="RequestImprDetail(props.row)"></q-btn>
        </template>
        <template v-if="col.name==='imprCost' && Boolean(props.row.sopImprovementId) && Boolean(props.row[col.name])">
          {{ props.row[col.name]  | toThousandFilter }}
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrol-master',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: '',
          title: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
        patrolTypeCd: null,
      },
      grid: {
        columns: [],
        data: [],
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSave: false,
      listUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.patrol.impr.list.url;
      this.updateUrl = transactionConfig.sai.patrol.impr.url;
      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;
      // code setting
      this.setHeader();
    },
    setHeader() {
      this.$comm.uploderSetting('PATROL_IMPR_RESULT').then(_result => {
        this.grid.columns = [
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 지적사항
            label: '점검일',
            style: 'width:120px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            // 지적사항
            label: '점검 주관/대상부서',
            style: 'width:140px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'hazardDisasterTypeName',
            field: 'hazardDisasterTypeName',
            label: '재해유형',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolContents',
            field: 'patrolContents',
            // 지적사항
            label: '유해위험요인',
            align: 'left',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '유해위험요인 사진',
            align: 'center',
            style: 'width:300px',
            type: 'attach',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'PATROL_IMPR_RESULT',
            keyText: 'saiPatrolImprId',
            uploaderSetting: _result,
            sortable: false,
            disableTarget: 'filedisabled',
            disableCon: 'N',
          },
          // {
          //   required: true,
          //   name: 'targetDeptName',
          //   field: 'targetDeptName',
          //   label: '조치부서',
          //   align: 'center',
          //   type: 'dept',
          //   deptCd: 'targetDeptCd',
          //   style: 'width:180px',
          //   sortable: false,
          // },
          // {
          //   required: true,
          //   setHeader: true,
          //   name: 'patrolRequestDate',
          //   field: 'patrolRequestDate',
          //   label: '조치완료 요청일',
          //   align: 'center',
          //   style: 'width:120px',
          //   type: 'date',
          //   sortable: false
          // },
          {
            label: '개선조치[조치 일정 준수]',
            align: 'center',
            child: [
              {
                name: 'request',
                field: 'request',
                label: '개선요청',
                align: 'center',
                style: 'width:100px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'ibmSuppleStepName',
                field: 'ibmSuppleStepName',
                label: '진행상태',
                align: 'center',
                style: 'width:120px',
                sortable: false
              },
              {
                name: 'link',
                field: 'link',
                label: '개선상세',
                align: 'center',
                type: 'custom',
                style: 'width:100px',
                sortable: false
              },
              {
                name: 'imprCost',
                field: 'imprCost',
                label: '개선시 발생비용(원)',
                type: 'custom',
                style: 'width:130px',
                sortable: false
              },
            ]
          },
          {
            name: 'remark',
            field: 'remark',
            // 지적사항
            label: '비고',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: false,
          },
        ]
        this.getList();
      })
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length === 2) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveItem() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        if(this.$comm.validTable(this.grid.columns, saveData)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.updateUrl;
              this.$http.type = 'PUT';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '변경된 데이터가 없습니다.', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    saveImpr() {
      let selectData = this.$refs["grid"].selected;
      
      let saveData = selectData.filter( x => {
        return x.sopImprovementId !== null
      });

      if (saveData.length > 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "개선이 요청된 건은 제외 후 다시 요청하시기 바랍니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        if(this.$comm.validTable(this.grid.columns, selectData)) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '조치부서로 개선요청을 하시겠습니까?', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.forEach(selectData, item => {
                item.imprDeptCd = this.$store.getters.user.deptCd;
                item.imprUserId = this.$store.getters.user.userId;
              });

              this.$http.url = this.updateUrl;
              this.$http.type = 'POST';
              this.$http.param = selectData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    },
    linkImprDetail(row) {
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    RequestImprDetail(row) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '개선요청을 작성 하시겠습니까?', // 저장하시겠습니까?
        type: 'info',
        // 확인 callback 함수
        confirmCallback: () => {
          row.imprDeptCd = this.$store.getters.user.deptCd;
          row.imprUserId = this.$store.getters.user.userId;
          row.actionFlag = 'D';

          this.$http.url = this.updateUrl;
          this.$http.type = 'POST';
          this.$http.param = row;
          this.$http.request((_result) => {
            this.linkImprDetail({sopImprovementId: _result.data});
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
